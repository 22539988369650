<template>
  <div class="trainingCourse">
    <div class="topt">
      <div class="topt-left">
        <p>建议内容：</p>
        <p>建议课时：{{datas.suggest_courses}}</p>
        <p>课程总价：{{datas.suggest_totalprice}}元</p>
      </div>
      <div class="topt-right">
        <p>账号：{{datas.user.username}}</p>
        <p>密码：{{datas.user.password}}</p>
        <p>剩余疗程：{{ datas.user.period }}</p>
      </div>
    </div>
    <ul class="bt back0">
      <li>序号</li>
      <li>类型</li>
      <li>详情</li>
      <li>视频</li>
      <li>训练内容</li>
    </ul>
    <div class="content-box">
      <ul class="bt xz" v-for="(item, idx) in datas.content" @click="">
        <li>{{item.sn}}</li>
        <li>{{item.type}}</li>
        <li>{{item.type_desc}}</li>
        <li @click.stop="opv(item.type_video)"><img :src='item.type_img' alt=""/></li>
        <li class="nr">
          <span v-for="items in item.detial.split('、')" :title="items">{{items}}</span>
        </li>
      </ul>
    </div>
    <div style="padding-top: .5vh">
      <el-button @click="buy">购买</el-button>
    </div>


    <el-dialog
        title="视频"
        :visible="dialogVisible"
        width="60%"
        height="50%"
        :before-close="handleClose"
        class="sp"
        >
      <span slot="footer" class="dialog-footer">
      <video width="100%" :src="vsrc" controls></video>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "trainingCourse",
  data() {
    return {
      datas: {content: [], user: {}},
      dialogVisible: false,
      vsrc: ''
    }
  },
  created() {
    this._api.training.getCurriculum()
    .then(res => {
      if (res.recommendResult && res.recommendResult.data) {
         this.datas = res.recommendResult.data
      }
    })
  },
  methods: {
    opv(src) {
      this.dialogVisible = true
      this.vsrc = src
    },
    handleClose() {this.dialogVisible = false},
    buy() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录，请登录', '提示').then(res => {
          this.$router.push({path: '/employeesLanding'})
        })
      }
      this._api.training.buyScheme()
      .then(res => {
        // console.log(res)
        if (res.Msg == 'success' && res.DirectInsertCCP_TopEyeResult) {
          this.$alert('下单成功，请支付订单！', '提示')
          .then(resp => {
            window.open(res.DirectInsertCCP_TopEyeResult)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .trainingCourse {
    height: 100%;
    overflow-y: auto;
  }
  .content-box {
    height: 77%;
    overflow-y: auto;
  }
  .trainingCourse::-webkit-scrollbar { width: 0 !important }
  .content-box::-webkit-scrollbar { width: 0 !important }
  .topt {
    display: flex;
    justify-content: space-between;
    padding: 0 2vw;
    padding-top: 2vh;
    .topt-left {
      display: flex;
      justify-content: start;
      font-size: 16px;
      p {padding-right: .6rem}
    }
    .topt-right {
      display: flex;
      justify-content: start;
      p {padding-left: .4rem}
    }
  }
  .bt {
    width: 100vw;
    display: flex;
    justify-content: start;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #000000;
    margin-bottom: .2rem;
    li {
      padding: .2rem 0;
      span {
        display: inline-block;
        padding: 0 .2rem;
        border: 1px solid #7f7e7e;
        border-radius: 5px;
        margin: .05rem;
        width: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    li:nth-child(1) {width: 8vw;}
    li:nth-child(2) {width: 12vw;}
    li:nth-child(3) {width: 34vw;}
    li:nth-child(4) {width: 12vw;}
    li:nth-child(5) {width: 34vw;}
    img {
      display: block;
      width: 70%;
      margin: 0 auto;
    }
    .nr {
      max-height: 20vh;
      text-align: left;
      overflow-y: auto;
    }
  }
  .xz {
    cursor: pointer;
  }
  .xz:hover {background: #eeeded;}
  .back0 {background: rgba(0,0,0,0.6); color: #FFFFFF;border: none;margin-top: 2vh;}
  .sp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
